<template>
  <div class="body-wrapper my-5">
    <div v-if="isLoading" class="mb-2">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <iframe :src="iframeUrl.concat('&iframe=1')" 
    style="width:100%;height:1200px;" @load="iframeLoaded"></iframe>
  </div>
</template>
  
<script>
  export default {
    name: 'IFrameComponent',
    props: {
      iframeUrl: String
    },
    components: {
    },
    methods: {
      iframeLoaded() {
        this.isLoading = false
      }
    },
    data() {
      return {
        isLoading: true
      };
    }
  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  