<template>
  <hr/>
  <footer class="footer">
    <div class="footer__container">
      <div id="hs_cos_wrapper_footer_page" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style="" data-hs-cos-general-type="widget" data-hs-cos-type="module">
        <div class="pwr-footer vanilla  pwr--light  lazyloaded" style="">
          <div class="pwr--full-width">
            <div class="pwr-footer-reduced__content pwr--clearfix">
              <div class="pwr-footer-reduced__logo pwr-footer__logo">
                <a href="//iti.com?hsLang=en-us"><img src="https://www.iti.com/hs-fs/hubfs/ITI-Logo-Slogan-CMYK-2021.png?width=120&amp;height=131&amp;name=ITI-Logo-Slogan-CMYK-2021.png" alt="ITI-Logo-Slogan-CMYK-2021" width="120" height="131" srcset="https://www.iti.com/hs-fs/hubfs/ITI-Logo-Slogan-CMYK-2021.png?width=60&amp;height=66&amp;name=ITI-Logo-Slogan-CMYK-2021.png 60w, https://www.iti.com/hs-fs/hubfs/ITI-Logo-Slogan-CMYK-2021.png?width=120&amp;height=131&amp;name=ITI-Logo-Slogan-CMYK-2021.png 120w, https://www.iti.com/hs-fs/hubfs/ITI-Logo-Slogan-CMYK-2021.png?width=180&amp;height=197&amp;name=ITI-Logo-Slogan-CMYK-2021.png 180w, https://www.iti.com/hs-fs/hubfs/ITI-Logo-Slogan-CMYK-2021.png?width=240&amp;height=262&amp;name=ITI-Logo-Slogan-CMYK-2021.png 240w, https://www.iti.com/hs-fs/hubfs/ITI-Logo-Slogan-CMYK-2021.png?width=300&amp;height=328&amp;name=ITI-Logo-Slogan-CMYK-2021.png 300w, https://www.iti.com/hs-fs/hubfs/ITI-Logo-Slogan-CMYK-2021.png?width=360&amp;height=393&amp;name=ITI-Logo-Slogan-CMYK-2021.png 360w" sizes="(max-width: 120px) 100vw, 120px"></a>
              </div>
              <div class="pwr-footer-reduced__statement pwr-rich-text">
                At ITI, we exist to serve and learn, every day. We partner with organizations to assess, transform, and continuously improve their hoisting and material handling activities by adapting and applying our structured methodology, expertise and solutions.     
              </div>
            </div>    
            <div class="pwr-footer-legal">
              <div class="pwr-footer-legal__content">
                <span class="pwr-footer__item pwr-footer-legal__notice">™©2022 Industrial Training International</span>
                <span class="pwr-footer__item pwr-footer-legal__menu pwr-js-menu">
                  <span id="hs_cos_wrapper_footer_page_" class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu" style="" data-hs-cos-general-type="widget" data-hs-cos-type="menu">
                    <div id="hs_menu_wrapper_footer_page_" class="hs-menu-wrapper active-branch no-flyouts hs-menu-flow-horizontal" role="navigation" data-sitemap-name="default" data-menu-id="55765979516" aria-label="Navigation Menu">
                      <ul role="menu">
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/privacy" role="menuitem">Privacy Policy</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/serviceagreements" role="menuitem">Service Agreements</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/training" role="menuitem">Solutions</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/learning-hub" role="menuitem">Learning Hub</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/resources" role="menuitem">Resource Center</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/about" role="menuitem">About ITI</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/blog" role="menuitem">Blog</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/about/careers" role="menuitem">Careers</a></li>
                        <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.iti.com/contact" role="menuitem">Contact Us</a></li>
                      </ul>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </footer>
</template>
  
  <script>
  export default {
    name: 'FooterComponent',
    props: {
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
  