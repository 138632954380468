<template>
    <!-- <nav class="navbar navbar-primary bg-primary">
        <div class="container">
            <a class="navbar-brand" href="#">
            <img src="../assets/img/logo.png" alt="ITI">
            </a>
        </div>
    </nav> -->
    <img src="../assets/img/header.jpg" />
</template>
  
  <script>
  export default {
    name: 'HeaderComponent',
    props: {
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .bg-primary {
    background-color: #1C2C5D;
    color: white;
  }
  </style>
  