<template>
    <HeaderComponent />
    <div class="body-wrapper my-5">
      <div class="alert alert-danger" v-if="hasError">{{ errorMessage }}</div>
      <div class="container-fluid">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button class="btn btn-primary me-md-2" v-if="addUrl !== '' && !backToItineraries" @click="$event => addItinerary()">Add Itinerary</button>
          <button class="btn btn-primary me-md-2" v-if="backToItineraries" @click="$event => backToItinerariesFunc()">Back To Itineraries</button>
        </div>
      </div>
      <div class="w-50 m-auto mt-5" :class="{hide: showWizard || showIframe}">
        <h2 class="fw-bolder">ITI Learning Path Builder</h2>
        <h4 class="fw-light text-muted">A training management tool-set designed to help you make the most of the ITI Training Programs.<br/>Complete a Learning Path Builder (LPG) - This tool provides a facility-level view of technical knowledge gaps and pinpoints ITI and 3rd party courses that match your training needs.</h4>
        <h4 class="fw-bold mt-5">So what are you waiting for?</h4>
        <input type="text" class="form-control m-auto w-50 d-block my-3 text-center" placeholder="Enter your email address..." v-model="customerEmail" />
        <button class="btn btn-primary btn-lg mt-3" @click="$event => fetchItineraries()">Start Now</button>
      </div>
      <div class="wizard-form" :class="{hide: !showWizard || showIframe}">
        <vue-form-wizard
          :form="questions"
          v-model="formData"
          @submit="handleSubmit(formData)"
        />
      </div>
      <div class="courses-list" v-if="showIframe">
        <CoursesComponent v-bind:iframeUrl="iframeUrl"  />
      </div>
    </div>
    <FooterComponent />
</template>
  
<script>
  import HeaderComponent from './HeaderComponent.vue'
  import FooterComponent from './FooterComponent.vue'
  import CoursesComponent from './IFrameComponent.vue'
  import VueFormWizard from '@anivive/vue3-form-wizard'
  import '../assets/css/wizard.css'; 
  import questions from '../questions.json';
  import axios from 'axios';
  export default {
    name: 'LPBuilder',
    props: {
    },
    components: {
      HeaderComponent,
      FooterComponent,
      VueFormWizard,
      CoursesComponent
    },
    methods: {
      handleSubmit(data) {
        if(this.customerEmail === '') {
          return;
        }
        data['email'] = this.customerEmail;
        const headers = { 
          "Authorization": "Bearer LHmRduM4cAZWpLqI1KrOtIoJ7YDdlVor"
        };
        axios.post("https://api.iti.com/api/v1/lpb", data, { headers })
          .then(response => {
            if(response.data.url != '') {
              this.iframeUrl = response.data.url
              this.showIframe = true
            }
            else {
              this.errorMessage = 'An error occurred while processing your request'
              this.hasError = true
              setTimeout(() => { this.hasError = false },3000)
            }
          })
          .catch(error => {
            this.errorMessage = error
            this.hasError = true
            setTimeout(() => { this.hasError = false },3000)
          });
      },
      showWizardClick() {
        this.showWizard = true
      },
      fetchItineraries() {
        if(this.customerEmail === '') {
          return;
        }
        const headers = { 
          "Authorization": "Bearer LHmRduM4cAZWpLqI1KrOtIoJ7YDdlVor"
        };
        axios.post("https://api.iti.com/api/v1/lpb/itineraries", { email: this.customerEmail }, { headers })
          .then(response => {
            if(response.data.url != '') {
              this.itinerariesUrl = response.data.url
              this.iframeUrl = response.data.url
              this.addUrl = response.data.add_url
              this.showIframe = true
            }
            else {
              this.showWizard = true
            }
          })
          .catch(() => {
            this.showWizard = true
          });
      },
      addItinerary() {
        this.iframeUrl = this.addUrl
        this.showIframe = true
        this.backToItineraries = true
      },
      backToItinerariesFunc() {
        this.iframeUrl = this.itinerariesUrl
        this.showIframe = true
        this.backToItineraries = false
      }
    },
    data() {
      return {
        formData: [],
        questions: questions,
        showWizard: false,
        showIframe: false,
        iframeUrl: '',
        hasError: false,
        errorMessage: '',
        customerEmail: '',
        addUrl: '',
        backToItineraries: false,
        itinerariesUrl: ''
      };
    }
  }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  